// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.
// This AWS Content is provided subject to the terms of the AWS Customer
// Agreement available at http://aws.amazon.com/agreement or other written
// agreement between Customer and either Amazon Web Services, Inc. or Amazon
// Web Services EMEA SARL or both.

import './App.css';
import "@cloudscape-design/global-styles/index.css"
import AppLayout from '@cloudscape-design/components/app-layout';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { default as CsIcon } from "@cloudscape-design/components/icon";
import Grid from "@cloudscape-design/components/grid";
import Flashbar from "@cloudscape-design/components/flashbar";
import Tabs from "@cloudscape-design/components/tabs";
import HelpPanel from "@cloudscape-design/components/help-panel";
import '@aws-amplify/ui-react/styles.css';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import LineChart from "@cloudscape-design/components/line-chart";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import { applyMode, applyDensity, Density, Mode } from '@cloudscape-design/global-styles';
import Container from "@cloudscape-design/components/container";
import SplitPanel from "@cloudscape-design/components/split-panel"
import PropertyFilter from '@cloudscape-design/components/property-filter';
import './styles/base.scss';
import './styles/top-navigation.scss';
import { Signer } from "@aws-amplify/core";
import { useCollection } from '@cloudscape-design/collection-hooks';
import Checkbox from "@cloudscape-design/components/checkbox";
import Wizard from "@cloudscape-design/components/wizard";
import { ScrollView } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import grafanaPreview from './grafana.png';
import yamcsPreview from './yamcs.png';
import quicksightPreview from './quicksight.png';

// import { useCollection } from '@cloudscape-design/collection-hooks';
// import {withRouter} from 'react-router';

import {
  // Box,
  // Button,
  Badge,
  Modal,
  DateRangePicker,
  Form,
  ButtonDropdown,
  ExpandableSection,
  Cards,
  ContentLayout,
  CollectionPreferences,
  Link,
  StatusIndicator,
  DatePicker,
  Popover,
  Select,
  // Header,
  SideNavigation,
  Multiselect,
  FormField,
  Input,
  ColumnLayout,
  Pagination,
  Table,
  TextFilter,
  Icon
} from '@cloudscape-design/components';

// react
import React, { useState, useEffect, useRef } from 'react';

import {
  defaultDarkModeOverride,
  HighlightMatch,
  withAuthenticator,
  ThemeProvider,
  useTheme
} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import { Amplify, Storage, API } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: 'CmocDigitalTwinApi',
        endpoint: "https://s1bl6n88ph.execute-api.us-east-1.amazonaws.com/prod",
        custom_header: async () => {
          return { Authorization: 'token' };
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
  // DataStore: {
  //   authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  // }
});

function App({ signOut, user }) {
  applyMode(Mode.Dark);

  function escapeFunc(event) {
    if (event.detail.id == 'signout') {
      console.log('siging out ', event);
      signOut()
    }

  }

  function AntennaScheduleTable() {
    const columnDefinitions=[
      {
        id: "vehicle",
        header: "Vehicle",
        // cell: item => <Link href="#">{item.scenarioId}</Link>,
        cell: item => item.vehicle,
        sortingField: "vehicle",
        isRowHeader: true
      },
      {
        id: "name",
        header: "Antenna",
        cell: item => item.name,
        sortingField: "name"
      },
      {
        id: "vehicle",
        header: "Vehicle",
        cell: item => item.vehicle,
        sortingField: "vehicle"
      },
      // {
      //   id: "status",
      //   header: "Status",
      //   cell: item => item.status,
      //   sortingField: "status"
      // },
      // {
      //   id: "alternatives",
      //   header: "Alternates",
      //   cell: item => item.alternatives,
      //   sortingField: "alternatives"
      // },
      {
        id: "startTime",
        header: "Start time (UTC)",
        cell: item => item.startTime,
        sortingField: "startTime"
      },
      {
        id: "endTime",
        header: "End time (UTC)",
        cell: item => item.endTime,
        sortingField: "endTime"
      },
      {
        id: "duration",
        header: "Duration (s)",
        cell: item => item.duration,
        sortingField: "duration"
      },
      {
        id: "cost",
        header: "Cost ($)",
        cell: item => item.cost,
        sortingField: "cost"
      },

    ]

    const paginationLabels = {
      nextPageLabel: 'Next page',
      pageLabel: pageNumber => `Go to page ${pageNumber}`,
      previousPageLabel: 'Previous page',
    };

    const pageSizePreference = {
      title: 'Select page size',
      options: [
        // { value: 10, label: '10 resources' },
        { value: 20, label: '20 resources' },
      ],
    };

    const visibleContentPreference = {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    };

    const collectionPreferencesProps = {
      pageSizePreference,
      visibleContentPreference,
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      title: 'Preferences',
    };

    function EmptyState({ title, subtitle, action }) {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }

    const TableNoMatchState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}>Clear filter</Button>
        </SpaceBetween>
      </Box>
    );

    const TableEmptyState = () => (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            {/* <b>No matches</b> */}
            <Box variant="p" color="inherit">
              No matches
            </Box>
          </div>
          {/* <Button>Create {resourceName.toLowerCase()}</Button> */}
        </SpaceBetween>
      </Box>
    );

    const filteringProperties = [
      {
        propertyLabel: 'Antenna',
        key: 'name',
        groupValuesLabel: 'Antennas',
        operators: [':', '!:', '=', '!='],
      },
      {
        propertyLabel: 'Vehicle',
        key: 'vehicle',
        groupValuesLabel: 'Vehicles',
        operators: [':', '!:', '=', '!='],
      },
      {
        propertyLabel: 'Start Time',
        key: 'startTime',
        groupValuesLabel: 'Times',
        operators: ['<=', '>=', '=', '!='],
      },
    ]

    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      [
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T17:00:00.000Z",
            "startTime": "2024-01-30T16:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T21:15:00.000Z",
            "startTime": "2024-01-30T21:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T20:15:00.000Z",
            "startTime": "2024-01-30T20:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:15:00.000Z",
            "startTime": "2024-01-30T02:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T09:00:00.000Z",
            "startTime": "2024-01-30T08:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:30:00.000Z",
            "startTime": "2024-01-30T11:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:15:00.000Z",
            "startTime": "2024-01-30T06:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T01:15:00.000Z",
            "startTime": "2024-01-30T01:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T08:15:00.000Z",
            "startTime": "2024-01-30T08:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T10:00:00.000Z",
            "startTime": "2024-01-30T09:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T09:15:00.000Z",
            "startTime": "2024-01-30T09:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T01:00:00.000Z",
            "startTime": "2024-01-30T00:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T11:15:00.000Z",
            "startTime": "2024-01-30T11:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T21:00:00.000Z",
            "startTime": "2024-01-30T20:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:30:00.000Z",
            "startTime": "2024-01-30T18:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T07:30:00.000Z",
            "startTime": "2024-01-30T07:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T15:45:00.000Z",
            "startTime": "2024-01-30T15:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T04:15:00.000Z",
            "startTime": "2024-01-30T04:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T23:15:00.000Z",
            "startTime": "2024-01-30T23:00:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-31T00:00:00.000Z",
            "startTime": "2024-01-30T23:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T17:15:00.000Z",
            "startTime": "2024-01-30T17:00:00.000Z",
            "vehicle": "27:HST"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T01:45:00.000Z",
            "startTime": "2024-01-30T01:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:30:00.000Z",
            "startTime": "2024-01-30T20:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T11:45:00.000Z",
            "startTime": "2024-01-30T11:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T06:00:00.000Z",
            "startTime": "2024-01-30T05:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T23:00:00.000Z",
            "startTime": "2024-01-30T22:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T07:00:00.000Z",
            "startTime": "2024-01-30T06:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T22:45:00.000Z",
            "startTime": "2024-01-30T22:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T08:00:00.000Z",
            "startTime": "2024-01-30T07:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:15:00.000Z",
            "startTime": "2024-01-30T00:00:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T14:00:00.000Z",
            "startTime": "2024-01-30T13:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T14:45:00.000Z",
            "startTime": "2024-01-30T14:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T08:30:00.000Z",
            "startTime": "2024-01-30T08:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T10:45:00.000Z",
            "startTime": "2024-01-30T10:30:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T13:15:00.000Z",
            "startTime": "2024-01-30T13:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T10:15:00.000Z",
            "startTime": "2024-01-30T10:00:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T07:45:00.000Z",
            "startTime": "2024-01-30T07:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T16:30:00.000Z",
            "startTime": "2024-01-30T16:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T23:30:00.000Z",
            "startTime": "2024-01-30T23:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T06:30:00.000Z",
            "startTime": "2024-01-30T06:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T14:15:00.000Z",
            "startTime": "2024-01-30T14:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T03:00:00.000Z",
            "startTime": "2024-01-30T02:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T07:15:00.000Z",
            "startTime": "2024-01-30T07:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T13:45:00.000Z",
            "startTime": "2024-01-30T13:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-05",
            "endTime": "2024-01-30T03:30:00.000Z",
            "startTime": "2024-01-30T03:15:00.000Z",
            "vehicle": "10:AQUA"
        },
        {
            "name": "AWS-08",
            "endTime": "2024-01-30T22:15:00.000Z",
            "startTime": "2024-01-30T22:00:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-06",
            "endTime": "2024-01-30T09:30:00.000Z",
            "startTime": "2024-01-30T09:15:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T13:00:00.000Z",
            "startTime": "2024-01-30T12:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T12:00:00.000Z",
            "startTime": "2024-01-30T11:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T12:15:00.000Z",
            "startTime": "2024-01-30T12:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T17:45:00.000Z",
            "startTime": "2024-01-30T17:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T16:15:00.000Z",
            "startTime": "2024-01-30T16:00:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T18:15:00.000Z",
            "startTime": "2024-01-30T18:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T04:30:00.000Z",
            "startTime": "2024-01-30T04:15:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T13:30:00.000Z",
            "startTime": "2024-01-30T13:15:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T23:45:00.000Z",
            "startTime": "2024-01-30T23:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AUWA-01",
            "endTime": "2024-01-30T04:45:00.000Z",
            "startTime": "2024-01-30T04:30:00.000Z",
            "vehicle": "23:ICON"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T01:30:00.000Z",
            "startTime": "2024-01-30T01:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "KU1S",
            "endTime": "2024-01-30T21:30:00.000Z",
            "startTime": "2024-01-30T21:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "26:Terra"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T16:00:00.000Z",
            "startTime": "2024-01-30T15:45:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T03:45:00.000Z",
            "startTime": "2024-01-30T03:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T05:15:00.000Z",
            "startTime": "2024-01-30T05:00:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T19:45:00.000Z",
            "startTime": "2024-01-30T19:30:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T05:00:00.000Z",
            "startTime": "2024-01-30T04:45:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T21:45:00.000Z",
            "startTime": "2024-01-30T21:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T12:30:00.000Z",
            "startTime": "2024-01-30T12:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T15:15:00.000Z",
            "startTime": "2024-01-30T15:00:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-04",
            "endTime": "2024-01-30T02:45:00.000Z",
            "startTime": "2024-01-30T02:30:00.000Z",
            "vehicle": "11:AURA"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T00:45:00.000Z",
            "startTime": "2024-01-30T00:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T05:30:00.000Z",
            "startTime": "2024-01-30T05:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-02",
            "endTime": "2024-01-30T15:30:00.000Z",
            "startTime": "2024-01-30T15:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T22:30:00.000Z",
            "startTime": "2024-01-30T22:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T02:30:00.000Z",
            "startTime": "2024-01-30T02:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T20:00:00.000Z",
            "startTime": "2024-01-30T19:45:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T15:00:00.000Z",
            "startTime": "2024-01-30T14:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T09:45:00.000Z",
            "startTime": "2024-01-30T09:30:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "TR-02",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "AWS-01",
            "endTime": "2024-01-30T02:00:00.000Z",
            "startTime": "2024-01-30T01:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T19:15:00.000Z",
            "startTime": "2024-01-30T19:00:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T14:30:00.000Z",
            "startTime": "2024-01-30T14:15:00.000Z",
            "vehicle": "18:SeaHawk-1"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T22:00:00.000Z",
            "startTime": "2024-01-30T21:45:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T06:45:00.000Z",
            "startTime": "2024-01-30T06:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "12:ICESat-2"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T18:45:00.000Z",
            "startTime": "2024-01-30T18:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-10",
            "endTime": "2024-01-30T11:00:00.000Z",
            "startTime": "2024-01-30T10:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T05:45:00.000Z",
            "startTime": "2024-01-30T05:30:00.000Z",
            "vehicle": "13:IRIS"
        },
        {
            "name": "AWS-09",
            "endTime": "2024-01-30T04:00:00.000Z",
            "startTime": "2024-01-30T03:45:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "SG-01",
            "endTime": "2024-01-30T08:45:00.000Z",
            "startTime": "2024-01-30T08:30:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T18:00:00.000Z",
            "startTime": "2024-01-30T17:45:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "AWS-07",
            "endTime": "2024-01-30T19:30:00.000Z",
            "startTime": "2024-01-30T19:15:00.000Z",
            "vehicle": "3:ISS"
        },
        {
            "name": "USAK-01",
            "endTime": "2024-01-30T00:30:00.000Z",
            "startTime": "2024-01-30T00:15:00.000Z",
            "vehicle": "8:MetOp"
        },
        {
            "name": "AS-01",
            "endTime": "2024-01-30T12:45:00.000Z",
            "startTime": "2024-01-30T12:30:00.000Z",
            "vehicle": "16:AIM"
        },
        {
            "name": "AWS-03",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "19:LandSat-7"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "14:OCO-2"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T20:45:00.000Z",
            "startTime": "2024-01-30T20:30:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "HBK-01",
            "endTime": "2024-01-30T03:15:00.000Z",
            "startTime": "2024-01-30T03:00:00.000Z",
            "vehicle": "9:SMAP"
        },
        {
            "name": "USHI-01",
            "endTime": "2024-01-30T10:30:00.000Z",
            "startTime": "2024-01-30T10:15:00.000Z",
            "vehicle": "21:Swift"
        },
        {
            "name": "MG-01",
            "endTime": "2024-01-30T17:30:00.000Z",
            "startTime": "2024-01-30T17:15:00.000Z",
            "vehicle": "20:GRACE-FO 2"
        },
        {
            "name": "KUS",
            "endTime": "2024-01-30T19:00:00.000Z",
            "startTime": "2024-01-30T18:45:00.000Z",
            "vehicle": "42:JPSS-1"
        },
        {
            "name": "AWS-11",
            "endTime": "2024-01-30T16:45:00.000Z",
            "startTime": "2024-01-30T16:30:00.000Z",
            "vehicle": "21:Swift"
        }
    ],
      {
        propertyFiltering: {
          filteringProperties,
          empty: <TableEmptyState />,
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
              }}
            />
          ),
        },
        pagination: { pageSize: preferencesAS.pageSize },
        // sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        sorting: {},
        // selection: {},
      }
    );
    const { selectedItems } = collectionProps;
    return (
      <Table
      {...collectionProps}
      // variant='borderless'
      resizableColumns={true}
      wrapLines
      columnDefinitions={columnDefinitions}
        visibleColumns={preferencesAS.visibileContent}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: 'Find data',
              filteringPlaceholder: 'Find data',
            }}
            // countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferencesAS}
            onConfirm={({ detail }) => setPreferencesAS(detail)}
          />
        }
      />
    )
      }




   
    

  function NewInput() {
    const [antennaNameInputValue, setAntennaNameInputValue] = React.useState();
    const [antennaSlpInputValue, setAntennaSlpInputValue] = React.useState();
    const [antennaLocationInputValue, setAntennaLocationInputValue] = React.useState();
    const [antennaLatitudeInputValue, setAntennaLatitudeInputValue] = React.useState();
    const [antennaLongitudeInputValue, setAntennaLongitudeInputValue] = React.useState();
    const [antennaStatusInputValue, setAntennaStatusInputValue] = React.useState();
    const [antennaSlewInputValue, setAntennaSlewInputValue] = React.useState();
    const [antennaAperatureInputValue, setAntennaAperatureInputValue] = React.useState();
    const [antennaBandsInputValue, setAntennaBandsInputValue] = React.useState();

    function SubmitAntenna(e) {
      e.preventDefault()
      console.log(antennaNameInputValue,
        antennaStatusInputValue.value,
        antennaLocationInputValue,
        antennaBandsInputValue[0].label,
        antennaAperatureInputValue,
        antennaSlewInputValue)
      const newAntenna =
      {
        name: antennaNameInputValue,
        status: <StatusIndicator type='success'>Active</StatusIndicator>,
        slp: antennaStatusInputValue.value,
        location: antennaLocationInputValue,
        bands: antennaBandsInputValue[0].label,
        aperature: antennaAperatureInputValue,
        slew: antennaSlewInputValue
      }

      console.log(newAntenna)
      antennaList.push(newAntenna)
      console.log(antennaList)
      setAntennaList(antennaList);
      setAntennaModalVisible(false);
      // console.log(e)
    }
    return (
      <form onSubmit={e => SubmitAntenna(e)}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button variant="primary">Create</Button>
            </SpaceBetween>
          }
        // header={<Header variant="h1">Form header</Header>}
        >
          {/* <Container
          header={
            <Header variant="h2">
              Form container header
            </Header>
          }
        > */}
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input
                value={antennaNameInputValue}
                onChange={event =>
                  setAntennaNameInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="SLP">
              <Select
                selectedOption={antennaSlpInputValue}
                onChange={({ detail }) =>
                  setAntennaSlpInputValue(detail.selectedOption)
                }
                placeholder='Choose SLP'
                options={[
                  { label: "AWS", value: "aws" },
                  { label: "KSAT", value: "ksat" },
                  { label: "NASA", value: "nasa" }
                ]}
              />
            </FormField>
            <FormField label="Location">
              <Input
                value={antennaLocationInputValue}
                onChange={event =>
                  setAntennaLocationInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="Latitude">
              <Input type='number' value={antennaLatitudeInputValue}
                onChange={event =>
                  setAntennaLatitudeInputValue(event.detail.value)
                } />

            </FormField>
            <FormField label="Longitude">
              <Input type='number' value={antennaLongitudeInputValue}
                onChange={event =>
                  setAntennaLongitudeInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Status">
              <Select
                selectedOption={antennaStatusInputValue}
                onChange={({ detail }) =>
                  setAntennaStatusInputValue(detail.selectedOption)
                }
                placeholder='Choose Status'
                options={[
                  { label: "Active", value: "active" },
                  { label: "Offline", value: "offline" }
                ]}
              />
            </FormField>
            <FormField label="Slew rate (deg/s)">
              <Input type='number' value={antennaSlewInputValue}
                onChange={event =>
                  setAntennaSlewInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Aperature (m)">
              <Input type='number' value={antennaAperatureInputValue}
                onChange={event =>
                  setAntennaAperatureInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Bands">
              <Multiselect
                selectedOptions={antennaBandsInputValue}
                onChange={({ detail }) =>
                  setAntennaBandsInputValue(detail.selectedOptions)
                }
                options={[
                  {
                    label: "S",
                    value: "s",
                    // description: "This is a description"
                  },
                  {
                    label: "X",
                    value: "x",
                    // iconName: "unlocked",
                    // labelTag: "This is a label tag"
                  },
                  {
                    label: "Ka",
                    value: "ka",
                    // iconName: "share",
                    // tags: ["Tags go here", "Tag1", "Tag2"],
                    // disabled: true
                  },
                  {
                    label: "Ku",
                    value: "ku",
                    // filteringTags: [
                    //   "filtering",
                    //   "tags",
                    //   "these are filtering tags"
                    // ]
                  },
                  // { label: "Option 5", value: "5" }
                ]}
                placeholder="Choose bands"
              />
            </FormField>
          </SpaceBetween>
          {/* </Container> */}
        </Form>
      </form>
    );
  }

  function SlpInput() {
    const [slpNameInputValue, setSlpNameInputValue] = React.useState();
    const [slpSpriceInputValue, setSlpSpriceInputValue] = React.useState();
    const [slpXpriceInputValue, setSlpXpriceInputValue] = React.useState();
    const [slpKapriceInputValue, setSlpKapriceInputValue] = React.useState();
    const [slpKupriceInputValue, setSlpKupriceInputValue] = React.useState();
    const [slpStatusInputValue, setSlpStatusInputValue] = React.useState();


    function SubmitSlp(e) {
      e.preventDefault()
      console.log(e);
      const newSlp =
      {
        name: slpNameInputValue,
        sprice: slpSpriceInputValue,
        xprice: slpXpriceInputValue,
        kaprice: slpKapriceInputValue,
        kuprice: slpKupriceInputValue,
        status: slpStatusInputValue.value

      }

      console.log(newSlp)
      slpList.push(newSlp)
      console.log(slpList)
      setSlpList(slpList);
      setSlpModalVisible(false);
      // console.log(e)
    }
    return (
      <form onSubmit={e => e.preventDefault()}>
        <Form onSubmit={e => console.log(e)}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button variant="primary" onClick={e => SubmitSlp(e)} >Create</Button>
            </SpaceBetween>
          }
        // header={<Header variant="h1">Form header</Header>}
        >
          {/* <Container
          header={
            <Header variant="h2">
              Form container header
            </Header>
          }
        > */}
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input
                value={slpNameInputValue}
                onChange={event =>
                  setSlpNameInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="S-band price ($/min)">
              <Input
                type='number'
                value={slpSpriceInputValue}
                onChange={event =>
                  setSlpSpriceInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="X-band price ($/min)">
              <Input
                type='number'
                value={slpXpriceInputValue}
                onChange={event =>
                  setSlpXpriceInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="Ka-band price ($/min)">
              <Input
                type='number'
                value={slpKapriceInputValue}
                onChange={event =>
                  setSlpKapriceInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="Ku-band price ($/min)">
              <Input
                type='number'
                value={slpKupriceInputValue}
                onChange={event =>
                  setSlpKupriceInputValue(event.detail.value)
                }
              />
            </FormField>
            <FormField label="Status">
              <Select
                selectedOption={slpStatusInputValue}
                onChange={({ detail }) =>
                  setSlpStatusInputValue(detail.selectedOption)
                }
                placeholder='Choose status'
                options={[
                  { label: "On contract", value: "active" },
                  { label: "Pending", value: "pending" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </FormField>
          </SpaceBetween>
          {/* </Container> */}
        </Form>
      </form>
      // <form onSubmit={e => SubmitAntenna(e)}>
      //   <Form
      //     actions={
      //       <SpaceBetween direction="horizontal" size="xs">
      //         <Button formAction="none" variant="link">
      //           Cancel
      //         </Button>
      //         <Button variant="primary">Create</Button>
      //       </SpaceBetween>
      //     }
      //   // header={<Header variant="h1">Form header</Header>}
      //   >
      //     {/* <Container
      //     header={
      //       <Header variant="h2">
      //         Form container header
      //       </Header>
      //     }
      //   > */}
      //     <SpaceBetween direction="vertical" size="l">
      //       <FormField label="Name">
      //         <Input
      //           value={antennaNameInputValue}
      //           onChange={event =>
      //             setAntennaNameInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="SLP">
      //         <Select
      //           selectedOption={antennaSlpInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaSlpInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose SLP'
      //           options={[
      //             { label: "AWS", value: "aws" },
      //             { label: "KSAT", value: "ksat" },
      //             { label: "NASA", value: "nasa" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Location">
      //         <Input
      //           value={antennaLocationInputValue}
      //           onChange={event =>
      //             setAntennaLocationInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="Latitude">
      //         <Input type='number' value={antennaLatitudeInputValue}
      //           onChange={event =>
      //             setAntennaLatitudeInputValue(event.detail.value)
      //           } />

      //       </FormField>
      //       <FormField label="Longitude">
      //         <Input type='number'  value={antennaLongitudeInputValue}
      //           onChange={event =>
      //             setAntennaLongitudeInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Status">
      //         <Select
      //           selectedOption={antennaStatusInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaStatusInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose Status'
      //           options={[
      //             { label: "Active", value: "active" },
      //             { label: "Offline", value: "offline" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Slew rate (deg/s)">
      //         <Input type='number' value={antennaSlewInputValue}
      //           onChange={event =>
      //             setAntennaSlewInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Aperature (m)">
      //         <Input type='number'  value={antennaAperatureInputValue}
      //           onChange={event =>
      //             setAntennaAperatureInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Bands">
      //         <Multiselect
      //           selectedOptions={antennaBandsInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaBandsInputValue(detail.selectedOptions)
      //           }
      //           options={[
      //             {
      //               label: "S",
      //               value: "s",
      //               // description: "This is a description"
      //             },
      //             {
      //               label: "X",
      //               value: "x",
      //               // iconName: "unlocked",
      //               // labelTag: "This is a label tag"
      //             },
      //             {
      //               label: "Ka",
      //               value: "ka",
      //               // iconName: "share",
      //               // tags: ["Tags go here", "Tag1", "Tag2"],
      //               // disabled: true
      //             },
      //             {
      //               label: "Ku",
      //               value: "ku",
      //               // filteringTags: [
      //               //   "filtering",
      //               //   "tags",
      //               //   "these are filtering tags"
      //               // ]
      //             },
      //             // { label: "Option 5", value: "5" }
      //           ]}
      //           placeholder="Choose bands"
      //         />
      //       </FormField>
      //     </SpaceBetween>
      //     {/* </Container> */}
      //   </Form>
      // </form>
    );
  }

  function MissionInput() {
    const [missionNameInputValue, setMissionNameInputValue] = React.useState();
    const [missionPriorityInputValue, setMissionPriorityInputValue] = React.useState();
    const [missionStatusInputValue, setMissionStatusInputValue] = React.useState();
    const [missionContactBudgetInputValue, setMissionContactBudgetInputValue] = React.useState();
    const [missionSlpPreferencesInputValue, setMissionSlpPreferencesInputValue] = React.useState();



    function SubmitMission(e) {
      e.preventDefault()
      console.log(e);
      const newMission =
      {
        name: missionNameInputValue,
        priority: missionPriorityInputValue,
        status: missionStatusInputValue.value,
        budget: missionContactBudgetInputValue,
        slpPreferences: missionSlpPreferencesInputValue[0].value,
      }

      // console.log(newSlp)
      missionList.push(newMission)
      console.log(slpList)
      setMissionList(missionList);
      setMissionModalVisible(false);
      // console.log(e)
    }
    return (
      <form onSubmit={e => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button variant="primary" onClick={e => SubmitMission(e)}>Create</Button>
            </SpaceBetween>
          }
        // header={<Header variant="h1">Form header</Header>}
        >
          {/* <Container
          header={
            <Header variant="h2">
              Form container header
            </Header>
          }
        > */}
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input value={missionNameInputValue}
                onChange={event =>
                  setMissionNameInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Priority">
              <Input type='number' value={missionPriorityInputValue}
                onChange={event =>
                  setMissionPriorityInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Status">
              <Select
                selectedOption={missionStatusInputValue}
                onChange={({ detail }) =>
                  setMissionStatusInputValue(detail.selectedOption)
                }
                placeholder='Choose Status'
                options={[
                  { label: "On contract", value: "active" },
                  { label: "Pending", value: "pending" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </FormField>
            <FormField label="Contact budget (min/day)">
              <Input type='number' value={missionContactBudgetInputValue}
                onChange={event =>
                  setMissionContactBudgetInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="SLP preferences">
              <Multiselect
                selectedOptions={missionSlpPreferencesInputValue}
                onChange={({ detail }) =>
                  setMissionSlpPreferencesInputValue(detail.selectedOptions)
                }
                options={[
                  {
                    label: "AWS",
                    value: "aws",
                    // description: "This is a description"
                  },
                  {
                    label: "KSAT",
                    value: "ksat",
                    // iconName: "unlocked",
                    // labelTag: "This is a label tag"
                  },
                  {
                    label: "NASA",
                    value: "nasa",
                    // iconName: "share",
                    // tags: ["Tags go here", "Tag1", "Tag2"],
                    // disabled: true
                  }
                  // { label: "Option 5", value: "5" }
                ]}
                placeholder="Choose SLP(s)"
              />
            </FormField>
          </SpaceBetween>
          {/* </Container> */}
        </Form>
      </form>
      // <form onSubmit={e => SubmitAntenna(e)}>
      //   <Form
      //     actions={
      //       <SpaceBetween direction="horizontal" size="xs">
      //         <Button formAction="none" variant="link">
      //           Cancel
      //         </Button>
      //         <Button variant="primary">Create</Button>
      //       </SpaceBetween>
      //     }
      //   // header={<Header variant="h1">Form header</Header>}
      //   >
      //     {/* <Container
      //     header={
      //       <Header variant="h2">
      //         Form container header
      //       </Header>
      //     }
      //   > */}
      //     <SpaceBetween direction="vertical" size="l">
      //       <FormField label="Name">
      //         <Input
      //           value={antennaNameInputValue}
      //           onChange={event =>
      //             setAntennaNameInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="SLP">
      //         <Select
      //           selectedOption={antennaSlpInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaSlpInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose SLP'
      //           options={[
      //             { label: "AWS", value: "aws" },
      //             { label: "KSAT", value: "ksat" },
      //             { label: "NASA", value: "nasa" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Location">
      //         <Input
      //           value={antennaLocationInputValue}
      //           onChange={event =>
      //             setAntennaLocationInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="Latitude">
      //         <Input type='number' value={antennaLatitudeInputValue}
      //           onChange={event =>
      //             setAntennaLatitudeInputValue(event.detail.value)
      //           } />

      //       </FormField>
      //       <FormField label="Longitude">
      //         <Input type='number'  value={antennaLongitudeInputValue}
      //           onChange={event =>
      //             setAntennaLongitudeInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Status">
      //         <Select
      //           selectedOption={antennaStatusInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaStatusInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose Status'
      //           options={[
      //             { label: "Active", value: "active" },
      //             { label: "Offline", value: "offline" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Slew rate (deg/s)">
      //         <Input type='number' value={antennaSlewInputValue}
      //           onChange={event =>
      //             setAntennaSlewInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Aperature (m)">
      //         <Input type='number'  value={antennaAperatureInputValue}
      //           onChange={event =>
      //             setAntennaAperatureInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Bands">
      //         <Multiselect
      //           selectedOptions={antennaBandsInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaBandsInputValue(detail.selectedOptions)
      //           }
      //           options={[
      //             {
      //               label: "S",
      //               value: "s",
      //               // description: "This is a description"
      //             },
      //             {
      //               label: "X",
      //               value: "x",
      //               // iconName: "unlocked",
      //               // labelTag: "This is a label tag"
      //             },
      //             {
      //               label: "Ka",
      //               value: "ka",
      //               // iconName: "share",
      //               // tags: ["Tags go here", "Tag1", "Tag2"],
      //               // disabled: true
      //             },
      //             {
      //               label: "Ku",
      //               value: "ku",
      //               // filteringTags: [
      //               //   "filtering",
      //               //   "tags",
      //               //   "these are filtering tags"
      //               // ]
      //             },
      //             // { label: "Option 5", value: "5" }
      //           ]}
      //           placeholder="Choose bands"
      //         />
      //       </FormField>
      //     </SpaceBetween>
      //     {/* </Container> */}
      //   </Form>
      // </form>
    );
  }

  function VehicleInput() {
    const [vehicleNameInputValue, setVehicleNameInputValue] = React.useState();
    const [vehicleMissionInputValue, setVehicleMissionInputValue] = React.useState();
    const [vehicleStatusInputValue, setVehicleStatusInputValue] = React.useState();
    const [vehicleOrbitInputValue, setVehicleOrbitInputValue] = React.useState();
    const [vehicleAperatureInputValue, setVehicleAperatureInputValue] = React.useState();
    const [vehicleBandsInputValue, setVehicleBandsInputValue] = React.useState();



    function SubmitVehicle(e) {
      e.preventDefault()
      console.log(e);
      const newVehicle =
      {
        name: vehicleNameInputValue,
        status: vehicleStatusInputValue.value,
        aperature: vehicleAperatureInputValue,
        orbit: vehicleOrbitInputValue.value,
        mission: vehicleMissionInputValue.value,
        bands: vehicleBandsInputValue[0].value,
      }

      // console.log(newSlp)
      vehicleList.push(newVehicle)
      // console.log(slpList)
      setVehicleList(vehicleList);
      setVehicleModalVisible(false);
      // console.log(e)
    }
    return (
      <form onSubmit={e => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button variant="primary" onClick={e => SubmitVehicle(e)}>Create</Button>
            </SpaceBetween>
          }
        // header={<Header variant="h1">Form header</Header>}
        >
          {/* <Container
      header={
        <Header variant="h2">
          Form container header
        </Header>
      }
    > */}
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
            <Input  value={vehicleNameInputValue}
                onChange={event =>
                  setVehicleNameInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Mission">
              <Select
                selectedOption={vehicleMissionInputValue}
                onChange={({ detail }) =>
                  setVehicleMissionInputValue(detail.selectedOption)
                }
                placeholder='Choose Mission'
                options={[
                  { label: "ISS", value: "iss" },
                  { label: "JPSS", value: "jpss" },
                  // { label: "KSAT", value: "ksat" },
                ]}
              />
            </FormField>
            <FormField label="Status">
              <Select
                selectedOption={vehicleStatusInputValue}
                onChange={({ detail }) =>
                  setVehicleStatusInputValue(detail.selectedOption)
                }
                placeholder='Choose Status'
                options={[
                  { label: "On contract", value: "active" },
                  { label: "Pending", value: "pending" },
                  { label: "Inactive", value: "inactive" },
                ]}
              />
            </FormField>
            <FormField label="Orbit type">
              <Select
                selectedOption={vehicleOrbitInputValue}
                onChange={({ detail }) =>
                  setVehicleOrbitInputValue(detail.selectedOption)
                }
                placeholder='Choose orbit type'
                options={[
                  { label: "LEO", value: "leo" },
                  { label: "MEO", value: "meo" },
                  { label: "HEO", value: "heo" },
                  { label: "GEO", value: "geo" },
                ]}
              />
            </FormField>

            <FormField label="Minimum aperature (m)">
            <Input type='number'  value={vehicleAperatureInputValue}
                onChange={event =>
                  setVehicleAperatureInputValue(event.detail.value)
                } />
            </FormField>
            <FormField label="Band(s)">
              <Multiselect
                selectedOptions={vehicleBandsInputValue}
                onChange={({ detail }) =>
                  setVehicleBandsInputValue(detail.selectedOptions)
                }
                options={[
                  {
                    label: "S",
                    value: "s",
                    // description: "This is a description"
                  },
                  {
                    label: "X",
                    value: "ksat",
                    // iconName: "unlocked",
                    // labelTag: "This is a label tag"
                  },
                  {
                    label: "Ka",
                    value: "ka",
                    // iconName: "share",
                    // tags: ["Tags go here", "Tag1", "Tag2"],
                    // disabled: true
                  },
                  {
                    label: "Ku",
                    value: "ku",
                    // iconName: "share",
                    // tags: ["Tags go here", "Tag1", "Tag2"],
                    // disabled: true
                  }
                  // { label: "Option 5", value: "5" }
                ]}
                placeholder="Choose band(s)"
              />
            </FormField>
          </SpaceBetween>
          {/* </Container> */}
        </Form>
      </form>
      // <form onSubmit={e => SubmitAntenna(e)}>
      //   <Form
      //     actions={
      //       <SpaceBetween direction="horizontal" size="xs">
      //         <Button formAction="none" variant="link">
      //           Cancel
      //         </Button>
      //         <Button variant="primary">Create</Button>
      //       </SpaceBetween>
      //     }
      //   // header={<Header variant="h1">Form header</Header>}
      //   >
      //     {/* <Container
      //     header={
      //       <Header variant="h2">
      //         Form container header
      //       </Header>
      //     }
      //   > */}
      //     <SpaceBetween direction="vertical" size="l">
      //       <FormField label="Name">
      //         <Input
      //           value={antennaNameInputValue}
      //           onChange={event =>
      //             setAntennaNameInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="SLP">
      //         <Select
      //           selectedOption={antennaSlpInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaSlpInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose SLP'
      //           options={[
      //             { label: "AWS", value: "aws" },
      //             { label: "KSAT", value: "ksat" },
      //             { label: "NASA", value: "nasa" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Location">
      //         <Input
      //           value={antennaLocationInputValue}
      //           onChange={event =>
      //             setAntennaLocationInputValue(event.detail.value)
      //           }
      //         />
      //       </FormField>
      //       <FormField label="Latitude">
      //         <Input type='number' value={antennaLatitudeInputValue}
      //           onChange={event =>
      //             setAntennaLatitudeInputValue(event.detail.value)
      //           } />

      //       </FormField>
      //       <FormField label="Longitude">
      //         <Input type='number'  value={antennaLongitudeInputValue}
      //           onChange={event =>
      //             setAntennaLongitudeInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Status">
      //         <Select
      //           selectedOption={antennaStatusInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaStatusInputValue(detail.selectedOption)
      //           }
      //           placeholder='Choose Status'
      //           options={[
      //             { label: "Active", value: "active" },
      //             { label: "Offline", value: "offline" }
      //           ]}
      //         />
      //       </FormField>
      //       <FormField label="Slew rate (deg/s)">
      //         <Input type='number' value={antennaSlewInputValue}
      //           onChange={event =>
      //             setAntennaSlewInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Aperature (m)">
      //         <Input type='number'  value={antennaAperatureInputValue}
      //           onChange={event =>
      //             setAntennaAperatureInputValue(event.detail.value)
      //           } />
      //       </FormField>
      //       <FormField label="Bands">
      //         <Multiselect
      //           selectedOptions={antennaBandsInputValue}
      //           onChange={({ detail }) =>
      //             setAntennaBandsInputValue(detail.selectedOptions)
      //           }
      //           options={[
      //             {
      //               label: "S",
      //               value: "s",
      //               // description: "This is a description"
      //             },
      //             {
      //               label: "X",
      //               value: "x",
      //               // iconName: "unlocked",
      //               // labelTag: "This is a label tag"
      //             },
      //             {
      //               label: "Ka",
      //               value: "ka",
      //               // iconName: "share",
      //               // tags: ["Tags go here", "Tag1", "Tag2"],
      //               // disabled: true
      //             },
      //             {
      //               label: "Ku",
      //               value: "ku",
      //               // filteringTags: [
      //               //   "filtering",
      //               //   "tags",
      //               //   "these are filtering tags"
      //               // ]
      //             },
      //             // { label: "Option 5", value: "5" }
      //           ]}
      //           placeholder="Choose bands"
      //         />
      //       </FormField>
      //     </SpaceBetween>
      //     {/* </Container> */}
      //   </Form>
      // </form>
    );
  }

  function Antennas() {

    return <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="Antennas currently tracked by the scheduling system."
          // actions={
          //   <Button variant="primary">Button</Button>
          // }
          >
            Antennas
          </Header>
          {/* {NewInput()} */}
          {/* <Alert>This is a generic alert.</Alert> */}
        </SpaceBetween>
      }
    >
      <Modal
        onDismiss={() => setAntennaModalVisible(false)}
        visible={antennaModalVisible}
        // footer={
        //   <Box float="right">
        //     <SpaceBetween direction="horizontal" size="xs">
        //       <Button variant="link">Cancel</Button>
        //       <Button variant="primary">Ok</Button>
        //     </SpaceBetween>
        //   </Box>
        // }
        header="Create antenna"
      >
        {/* <form>

          <Wizard
            i18nStrings={{
              stepNumberLabel: stepNumber =>
                `Step ${stepNumber}`,
              collapsedStepsLabel: (stepNumber, stepsCount) =>
                `Step ${stepNumber} of ${stepsCount}`,
              skipToButtonLabel: (step, stepNumber) =>
                `Skip to ${step.title}`,
              navigationAriaLabel: "Steps",
              cancelButton: "Cancel",
              previousButton: "Previous",
              nextButton: "Next",
              submitButton: "Start simulation",
              optional: "optional"
            }}
            onNavigate={({ detail }) =>
              setActiveStepIndex(detail.requestedStepIndex)
            }
            onSubmit={({ detail }) =>
              showFlashbar(detail)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[
              {
                title: "Orbit parameters",
                info: <Link variant="info">Info</Link>,
                description:
                  "Orbit stuff.",
                content: (
                  <Container
                    header={
                      <Header variant="h2">
                        Orbit parameters
                      </Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="l">
                      <FormField label="Radius (km)">
                        <Input inputMode='decimal'
                          value={radius}
                          onChange={event =>
                            setRadius(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Inclination (deg)">
                        <Input
                          inputMode='decimal'
                          value={inclination}
                          onChange={event =>
                            setInclination(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="True anomaly (deg)">
                        <Input
                          inputMode='decimal'
                          value={trueAnomaly}
                          onChange={event =>
                            setTrueAnomaly(event.detail.value)
                          } />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                )
              },
              {
                title: "Spacecraft configuration",
                content: (
                  <Container
                    header={
                      <Header variant="h2">
                        Form container header
                      </Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="l">
                      <FormField label="Solar panel area (m2)">
                        <Input inputMode='decimal'
                          value={solarPanelArea}
                          onChange={event =>
                            setSolarPanelArea(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Solar panel efficiency (%)">
                        <Input inputMode='decimal'
                          value={solarPanelEfficiency}
                          onChange={event =>
                            setSolarPanelEfficiency(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Attitude rate [x, y, z]">
                        <SpaceBetween direction='horizontal' size='l'>
                          <Input inputMode='decimal'
                            value={attRateX}
                            onChange={event =>
                              setAttRateX(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={attRateY}
                            onChange={event =>
                              setAttRateY(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={attRateZ}
                            onChange={event =>
                              setAttRateZ(event.detail.value)
                            } />
                        </SpaceBetween>

                      </FormField>
                      <FormField label="Attitude control parameters [K, P, Ki, Integral Limit]">
                        <SpaceBetween direction='horizontal' size='l'>
                          <Input inputMode='decimal'
                            value={controlK}
                            onChange={event =>
                              setControlK(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={controlP}
                            onChange={event =>
                              setControlP(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={controlKi}
                            onChange={event =>
                              setControlKi(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={controlIntLim}
                            onChange={event =>
                              setControlIntLim(event.detail.value)
                            } />
                        </SpaceBetween>
                      </FormField>
                    </SpaceBetween>
                  </Container>
                ),
                // isOptional: true
              },
              {
                title: "Time parameters",
                content: (
                  <Container
                    header={
                      <Header variant="h2">
                        Form container header
                      </Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="l">
                      <FormField label="Simulation type">
                        <Select
                          selectedOption={simType}
                          onChange={({ detail }) =>
                            setSimType(detail.selectedOption)
                          }

                          options={[
                            { label: "Archive", value: "archive" },
                            { label: "Realtime", value: "realtime" }
                          ]}
                        />
                      </FormField>
                      <FormField label="Interval duration (ticks)">
                        <Input inputMode='decimal'
                          value={intervalDuration}
                          onChange={event =>
                            setIntervalDuration(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Simulation tick interval (s)">
                        <Input inputMode='decimal'
                          value={tickInterval}
                          onChange={event =>
                            setTickInterval(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Output measurement interval (s)">
                        <Input inputMode='decimal'
                          value={measurementInterval}
                          onChange={event =>
                            setMeasurementInterval(event.detail.value)
                          } />
                      </FormField>
                      <FormField label="Epoch start date [year, month, day]">
                        <SpaceBetween direction='horizontal' size='l'>
                          <Input inputMode='decimal'
                            value={startYear}
                            onChange={event =>
                              setStartYear(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={startMonth}
                            onChange={event =>
                              setStartMonth(event.detail.value)
                            } />
                          <Input inputMode='decimal'
                            value={startDay}
                            onChange={event =>
                              setStartDay(event.detail.value)
                            } />
                        </SpaceBetween>

                      </FormField>
                    </SpaceBetween>
                  </Container>
                ),
                // isOptional: true
              },
              {
                title: "Review and submit",
                content: (
                  <SpaceBetween size="l">
                    <SpaceBetween size="xs">
                      <Header
                        variant="h3"
                        actions={
                          <Button
                            onClick={() => setActiveStepIndex(0)}
                          >
                            Edit
                          </Button>
                        }
                      >
                        Step 1: Orbit parameters
                      </Header>
                      <Container
                      // header={
                      //   <Header variant="h2">
                      //     Selections
                      //   </Header>
                      // }
                      >
                        <ColumnLayout
                          columns={3}
                          variant="text-grid"
                        >
                          <div>
                            <Box variant="awsui-key-label">
                              Radius (km)
                            </Box>
                            <div>{radius}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Inclination (deg)
                            </Box>
                            <div>{inclination}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              True anomaly (deg)
                            </Box>
                            <div>{trueAnomaly}</div>
                          </div>
                        </ColumnLayout>
                      </Container>
                    </SpaceBetween>
                    <SpaceBetween size="xs">
                      <Header
                        variant="h3"
                        actions={
                          <Button
                            onClick={() => setActiveStepIndex(1)}
                          >
                            Edit
                          </Button>
                        }
                      >
                        Step 2: Spacecraft
                      </Header>
                      <Container
                      // header={
                      //   <Header variant="h2">
                      //     Selections
                      //   </Header>
                      // }
                      >
                        <ColumnLayout
                          columns={3}
                          variant="text-grid"
                        >
                          <div>
                            <Box variant="awsui-key-label">
                              Solar panel area (m2)
                            </Box>
                            <div>{solarPanelArea}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Solar panel efficiency (%)
                            </Box>
                            <div>{solarPanelEfficiency}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Attitude rate (deg/s)
                            </Box>
                            <div>[{attRateX}, {attRateY}, {attRateZ}]</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Attitude control parameters [K, P, Ki, Integral Limit]
                            </Box>
                            <div>[{controlK}, {controlP}, {controlKi}, {controlIntLim}]</div>
                          </div>
                        </ColumnLayout>
                      </Container>
                    </SpaceBetween>
                    <SpaceBetween size="xs">
                      <Header
                        variant="h3"
                        actions={
                          <Button
                            onClick={() => setActiveStepIndex(2)}
                          >
                            Edit
                          </Button>
                        }
                      >
                        Step 3: Time parameters
                      </Header>
                      <Container
                      // header={
                      //   <Header variant="h2">
                      //     Selections
                      //   </Header>
                      // }
                      >
                        <ColumnLayout
                          columns={3}
                          variant="text-grid"
                        >
                          <div>
                            <Box variant="awsui-key-label">
                              Simulation type
                            </Box>
                            <div>{simType.label}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Simulation interval (ticks)
                            </Box>
                            <div>{intervalDuration}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Tick interval (s)
                            </Box>
                            <div>{tickInterval}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Measurement interval (s)
                            </Box>
                            <div>{measurementInterval}</div>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">
                              Start date epoch [year, month, day]
                            </Box>
                            <div>{startYear}-{startMonth}-{startDay}</div>
                          </div>
                        </ColumnLayout>
                      </Container>
                    </SpaceBetween>
                  </SpaceBetween>
                )
              }
            ]}
          />
        </form> */}
        {NewInput()}
      </Modal>
      <Table
        // onSelectionChange={({ detail }) =>
        //   setSelectedItems(detail.selectedItems)
        // }
        // selectedItems={selectedItems}
        // ariaLabels={{
        //   selectionGroupLabel: "Items selection",
        //   allItemsSelectionLabel: ({ selectedItems }) =>
        //     `${selectedItems.length} ${
        //       selectedItems.length === 1 ? "item" : "items"
        //     } selected`,
        //   itemSelectionLabel: ({ selectedItems }, item) =>
        //     item.name
        // }}

        resizableColumns
        wrapLines
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            // cell: item => <Link href="#">{item.scenarioId}</Link>,
            cell: item => item.name,
            sortingField: "name",
            isRowHeader: true
          },
          {
            id: "status",
            header: "Status",
            cell: item => item.status,
            sortingField: "status"
          },
          {
            id: "slp",
            header: "SLP",
            cell: item => item.slp,
            sortingField: "slp"
          },
          {
            id: "location",
            header: "Location",
            cell: item => item.location,
            sortingField: "location"
          },
          {
            id: "slew",
            header: "Slew rate (deg/s)",
            cell: item => item.slew,
            sortingField: "slew"
          },
          {
            id: "bands",
            header: "Bands",
            cell: item => item.bands,
            sortingField: "bands"
          },
          {
            id: "aperature",
            header: "Aperature size (m)",
            cell: item => item.aperature,
            sortingField: "aperature"
          },


        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "status", visible: true },
          { id: "slp", visible: true },
          { id: "location", visible: true },
          { id: "bands", visible: true },
          { id: "aperature", visible: true },
          { id: "slew", visible: true }
        ]}
        items={antennaList


        }
        loadingText="Loading resources"
        // selectionType="multi"
        // trackBy="name"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find antennas"
            filteringText=""
          />
        }
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                {/* <ButtonDropdown
                      items={[
                        {
                          text: "Deactivate",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Activate",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Status 3",
                          id: "rn",
                          disabled: false
                        },
                        {
                          text: "View details",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Edit",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Delete",
                          id: "rn",
                          disabled: false
                        }
                      ]}
                    >
                      Actions
                    </ButtonDropdown> */}
                <Button
                  iconName="refresh"
                  // variant="inline-icon"
                  onClick={() => {
                    getSimStatus();
                  }}
                >

                </Button>
                <Button>Edit antenna</Button>
                <Button variant="primary" onClick={() => {
                  setAntennaModalVisible(true);
                }}>
                  Create antenna
                </Button>
              </SpaceBetween>
            }
          // counter={
          //   selectedItems.length
          //     ? "(" + selectedItems.length + "/10)"
          //     : "(10)"
          // }
          >

          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={2} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "name", visible: true },
                { id: "status", visible: true },
                { id: "slp", visible: true },
                { id: "location", visible: true },
                { id: "bands", visible: true },
                { id: "aperature", visible: true },
                { id: "slew", visible: true }
              ]
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            wrapLinesPreference={{}}
            stripedRowsPreference={{}}
            contentDensityPreference={{}}
            contentDisplayPreference={{
              options: [
                {
                  id: "variable",
                  label: "Variable name",
                  alwaysVisible: true
                },
                { id: "value", label: "Text value" },
                { id: "type", label: "Type" },
                { id: "description", label: "Description" }
              ]
            }}
            stickyColumnsPreference={{
              firstColumns: {
                title: "Stick first column(s)",
                description:
                  "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "First column", value: 1 },
                  { label: "First two columns", value: 2 }
                ]
              },
              lastColumns: {
                title: "Stick last column",
                description:
                  "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "Last column", value: 1 }
                ]
              }
            }}
          />
        }
      />


    </ContentLayout>


  }

  function Slps() {

    return <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="Space link providers (SLPs) currently tracked by the scheduling system."
          // actions={
          //   <Button variant="primary">Button</Button>
          // }
          >
            Space Link Providers
          </Header>

          {/* <Alert>This is a generic alert.</Alert> */}
        </SpaceBetween>
      }
    >

      <Modal
        onDismiss={() => setSlpModalVisible(false)}
        visible={slpModalVisible}
        // footer={
        //   <Box float="right">
        //     <SpaceBetween direction="horizontal" size="xs">
        //       <Button variant="link">Cancel</Button>
        //       <Button variant="primary">Ok</Button>
        //     </SpaceBetween>
        //   </Box>
        // }
        header="Create SLP"
      >
        {SlpInput()}


      </Modal>
      <Table
        // onSelectionChange={({ detail }) =>
        //   setSelectedItems(detail.selectedItems)
        // }
        // selectedItems={selectedItems}
        // ariaLabels={{
        //   selectionGroupLabel: "Items selection",
        //   allItemsSelectionLabel: ({ selectedItems }) =>
        //     `${selectedItems.length} ${
        //       selectedItems.length === 1 ? "item" : "items"
        //     } selected`,
        //   itemSelectionLabel: ({ selectedItems }, item) =>
        //     item.name
        // }}

        resizableColumns
        wrapLines
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            // cell: item => <Link href="#">{item.scenarioId}</Link>,
            cell: item => item.name,
            sortingField: "name",
            isRowHeader: true
          },
          {
            id: "status",
            header: "Status",
            cell: item => item.status,
            sortingField: "status"
          },
          {
            id: "antennas",
            header: "Antennas",
            cell: item => item.antennas,
            sortingField: "antennas"
          },
          {
            id: "sprice",
            header: "S-band price ($/min)",
            cell: item => item.sprice,
            sortingField: "sprice"
          },
          {
            id: "xprice",
            header: "X-band price ($/min)",
            cell: item => item.xprice,
            sortingField: "xprice"
          },
          {
            id: "kaprice",
            header: "Ka-band price ($/min)",
            cell: item => item.kaprice,
            sortingField: "kaprice"
          },
          {
            id: "kuprice",
            header: "Ku-band price ($/min)",
            cell: item => item.kuprice,
            sortingField: "kuprice"
          }
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "status", visible: true },
          { id: "antennas", visible: true },
          { id: "sprice", visible: true },
          { id: "xprice", visible: true },
          { id: "kaprice", visible: true },
          { id: "kuprice", visible: true }
        ]}
        items={slpList


        }
        loadingText="Loading resources"
        // selectionType="multi"
        // trackBy="name"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find SLPs"
            filteringText=""
          />
        }
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                {/* <ButtonDropdown
                      items={[
                        {
                          text: "Deactivate",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Activate",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Status 3",
                          id: "rn",
                          disabled: false
                        },
                        {
                          text: "View details",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Edit",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Delete",
                          id: "rn",
                          disabled: false
                        }
                      ]}
                    >
                      Actions
                    </ButtonDropdown> */}
                <Button
                  iconName="refresh"
                  // variant="inline-icon"
                  onClick={() => {
                    getSimStatus();
                  }}
                >

                </Button>
                <Button>Edit SLP</Button>
                <Button variant="primary" onClick={() => {
                  setSlpModalVisible(true);
                }}>
                  Create SLP
                </Button>
              </SpaceBetween>
            }
          // counter={
          //   selectedItems.length
          //     ? "(" + selectedItems.length + "/10)"
          //     : "(10)"
          // }
          >

          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={2} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "name", visible: true },
                { id: "status", visible: true },
                { id: "slp", visible: true },
                { id: "location", visible: true },
                { id: "bands", visible: true },
                { id: "aperature", visible: true },
                { id: "slew", visible: true }
              ]
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            wrapLinesPreference={{}}
            stripedRowsPreference={{}}
            contentDensityPreference={{}}
            contentDisplayPreference={{
              options: [
                {
                  id: "variable",
                  label: "Variable name",
                  alwaysVisible: true
                },
                { id: "value", label: "Text value" },
                { id: "type", label: "Type" },
                { id: "description", label: "Description" }
              ]
            }}
            stickyColumnsPreference={{
              firstColumns: {
                title: "Stick first column(s)",
                description:
                  "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "First column", value: 1 },
                  { label: "First two columns", value: 2 }
                ]
              },
              lastColumns: {
                title: "Stick last column",
                description:
                  "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "Last column", value: 1 }
                ]
              }
            }}
          />
        }
      />


    </ContentLayout>


  }


  const profileActions = [
    // { type: 'button', id: 'profile', text: 'Profile' },
    // { type: 'button', id: 'preferences', text: 'Preferences' },
    // { type: 'button', id: 'security', text: 'Security' },
    // {
    //   type: 'menu-dropdown',
    //   id: 'support-group',
    //   text: 'Support',
    //   items: [
    //     {
    //       id: 'documentation',
    //       text: 'Documentation',
    //       href: '#',
    //       external: true,
    //       externalIconAriaLabel: ' (opens in new tab)',
    //     },
    //     { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
    //     { id: 'support', text: 'Customer support' },

    //   ],
    // },
    // onClick={() => moveToAlert(flux['values']['deviceID'])}>
    { type: 'button', id: 'signout', text: 'Sign out' },

  ];

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
    overflowMenuBackIconAriaLabel: 'Back',
    overflowMenuDismissIconAriaLabel: 'Close menu',
  };

  const [
    activeStepIndex,
    setActiveStepIndex
  ] = React.useState(0);

  const [radius, setRadius] = React.useState("6671");
  const [inclination, setInclination] = React.useState("30");
  const [trueAnomaly, setTrueAnomaly] = React.useState("0");
  const [solarPanelArea, setSolarPanelArea] = React.useState("0.1");
  const [solarPanelEfficiency, setSolarPanelEfficiency] = React.useState("0.2");
  const [intervalDuration, setIntervalDuration] = React.useState("4000");
  const [tickInterval, setTickInterval] = React.useState("0.05");
  const [measurementInterval, setMeasurementInterval] = React.useState("5");
  const [startYear, setStartYear] = React.useState(2023);
  const [startMonth, setStartMonth] = React.useState(12);
  const [startDay, setStartDay] = React.useState(19);
  const [simType, setSimType] = React.useState({ label: "Archive", value: "archive" });
  const [attRateX, setAttRateX] = React.useState(0.2);
  const [attRateY, setAttRateY] = React.useState(0.1);
  const [attRateZ, setAttRateZ] = React.useState(0.05);
  const [controlK, setControlK] = React.useState(3.5);
  const [controlP, setControlP] = React.useState(30);
  const [controlKi, setControlKi] = React.useState(-1);
  const [controlIntLim, setControlIntLim] = React.useState(-20);
  const [antennaModalVisible, setAntennaModalVisible] = React.useState(false);
  const [slpModalVisible, setSlpModalVisible] = React.useState(false);
  const [missionModalVisible, setMissionModalVisible] = React.useState(false);
  const [vehicleModalVisible, setVehicleModalVisible] = React.useState(false);
  const [scheduleModalVisible, setScheduleModalVisible] = React.useState(false);
  const [daterange, setDaterange] = React.useState();
  const [preferencesAS, setPreferencesAS] = useState({ pageSize: 20, visibileContent:['name', 'vehicle', 'startTime', 'endTime']})
  const [filterState, setFilterState] = useState([]); 
  const [antennaList, setAntennaList] = React.useState([
    {
      name: 'AWS-01',
      status: <StatusIndicator type='success'>Active</StatusIndicator>,
      slp: 'AWS',
      location: 'Oregon, USA',
      bands: <SpaceBetween direction='horizontal' size='m'>
        <Badge>S</Badge>
        <Badge>X</Badge>
      </SpaceBetween>,
      aperature: 10,
      slew: 1.5
    },
    {
      name: 'AWS-02',
      status: <StatusIndicator type='success'>Active</StatusIndicator>,
      slp: 'AWS',
      location: 'Sydney, Australia',
      bands: <SpaceBetween direction='horizontal' size='m'>
        <Badge>S</Badge>
        <Badge>X</Badge>
      </SpaceBetween>,
      aperature: 10,
      slew: 1.5
    }
  ])
  const [slpList, setSlpList] = React.useState([
    {
      name: 'AWS',
      status: <StatusIndicator type='success'>On contract</StatusIndicator>,
      antennas: <SpaceBetween direction='horizontal' size='m'>
        <Badge><Link color='inverted'>AWS-01</Link></Badge>
        <Badge><Link color='inverted'>AWS-02</Link></Badge>
      </SpaceBetween>,
      sprice: 10,
      xprice: 10,
      kaprice: "",
      kuprice: ''
    },
    {
      name: 'KSAT',
      status: <StatusIndicator type='pending'>Pending</StatusIndicator>,
      antennas: <SpaceBetween direction='horizontal' size='m'>
        <Badge><Link color='inverted'>KSAT-01</Link></Badge>
        <Badge><Link color='inverted'>KSAT-02</Link></Badge>
      </SpaceBetween>,
      sprice: "TBD",
      xprice: "TBD",
      kaprice: "TBD",
      kuprice: "TBD"
    },
    {
      name: 'NASA',
      status: <StatusIndicator type='success'>On contract</StatusIndicator>,
      antennas: <SpaceBetween direction='horizontal' size='m'>
        <Badge><Link color='inverted'>NASA-01</Link></Badge>
        <Badge><Link color='inverted'>NASA-02</Link></Badge>
      </SpaceBetween>,
      sprice: "TBD",
      xprice: "TBD",
      kaprice: "TBD",
      kuprice: "TBD"
    },
  ])
  const [missionList, setMissionList] = React.useState([
    {
      name: 'ISS',
      status: <StatusIndicator type='success'>On contract</StatusIndicator>,
      budget: 60,
      vehicles: <Badge><Link color='inverted'>ISS</Link></Badge>,
      slppreference: <SpaceBetween direction='horizontal' size='m'>
        <Badge><Link color='inverted'>AWS</Link></Badge>
        <Badge><Link color='inverted'>NASA</Link></Badge>
        <Badge><Link color='inverted'>KSAT</Link></Badge>
      </SpaceBetween>
    },
    {
      name: 'JPSS',
      status: <StatusIndicator type='success'>On contract</StatusIndicator>,
      budget: 60,
      vehicles: <Badge><Link color='inverted'>JPSS-1</Link></Badge>,
      slppreference: <SpaceBetween direction='horizontal' size='m'>
        <Badge><Link color='inverted'>AWS</Link></Badge>
        <Badge><Link color='inverted'>NASA</Link></Badge>
        <Badge><Link color='inverted'>KSAT</Link></Badge>
      </SpaceBetween>
    }
  ])

  const [vehicleList, setVehicleList] = React.useState(
    [
      {
        name: 'ISS',
        status: <StatusIndicator type='success'>Operational</StatusIndicator>,
        aperature: 10,
        mission: <Badge><Link color='inverted'>ISS</Link></Badge>,
        bands: <SpaceBetween direction='horizontal' size='m'>
          <Badge><Link color='inverted'>S</Link></Badge>
          <Badge><Link color='inverted'>Ka</Link></Badge>
        </SpaceBetween>
      },
      {
        name: 'JPSS-1',
        status: <StatusIndicator type='success'>Operational</StatusIndicator>,
        aperature: 10,
        mission: <Badge><Link color='inverted'>JPSS</Link></Badge>,
        bands: <SpaceBetween direction='horizontal' size='m'>
          <Badge><Link color='inverted'>S</Link></Badge>
          <Badge><Link color='inverted'>Ka</Link></Badge>
        </SpaceBetween>
      }
    ]
  )


  const [simItems, setSimItems] = React.useState([
  ]);
  const [simId, setSimId] = React.useState('');
  const [activeHref, setActiveHref] = React.useState(
    useLocation().pathname
  );
  const [contentType, setContentType] = React.useState(
    "wizard"
  );
  const [simResultUrl, setSimResultUrl] = React.useState([]);

  const navigate = useNavigate();

  async function getSimStatus() {
    // const signedURL = await Storage.get('22f4cfba-7dc1-11ee-9ba1-bd2b9e0bd774.json');

    await API.get('CmocDigitalTwinApi', '/status')
      .then((response) => {
        console.log(response);
        setSimResultUrl(response)

      }
      )
      .catch((error) => {
        console.log(error.response);
      });

    // await Storage.list('',  { pageSize: '5' }) // for listing ALL files without prefix, pass '' instead
    //   .then(({ results }) => {
    //     setSimResultUrl(results.slice(1, results.length))
    //   })
    //   .catch((err) => console.log(err));
    // ;

  }



  useEffect(() => {
    // Update the document title using the browser API
    getSimStatus()
  }, []);



  function ViewSimulations() {


    return <>
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              info={<Link>Info</Link>}
              description="This is a generic description used in the header."
            // actions={
            //   <Button variant="primary">Button</Button>
            // }
            >
              View simulations
            </Header>

            {/* <Alert>This is a generic alert.</Alert> */}
          </SpaceBetween>
        }
      >
        <Table
          // onSelectionChange={({ detail }) =>
          //   setSelectedItems(detail.selectedItems)
          // }
          // selectedItems={selectedItems}
          // ariaLabels={{
          //   selectionGroupLabel: "Items selection",
          //   allItemsSelectionLabel: ({ selectedItems }) =>
          //     `${selectedItems.length} ${
          //       selectedItems.length === 1 ? "item" : "items"
          //     } selected`,
          //   itemSelectionLabel: ({ selectedItems }, item) =>
          //     item.name
          // }}
          resizableColumns
          wrapLines
          columnDefinitions={[
            {
              id: "scenarioId",
              header: "Scenario ID",
              // cell: item => <Link href="#">{item.scenarioId}</Link>,
              cell: item => item.scenarioId,
              sortingField: "scenarioId",
              isRowHeader: true
            },
            {
              id: "status",
              header: "Status",
              cell: item => item.status,
              sortingField: "status"
            },
            {
              id: "date",
              header: "Creation date",
              cell: item => item.date,
              sortingField: "date"
            },
            // {
            //   id: "owner",
            //   header: "Creator",
            //   cell: item => item.owner
            // },
            {
              id: "inputs",
              header: "Input parameters",
              cell: item => item.inputs
            },
            {
              id: "outputs",
              header: "Output data",
              cell: item => item.outputs,
            },

          ]}
          columnDisplay={[
            { id: "scenarioId", visible: true },
            { id: "status", visible: true },
            { id: "date", visible: true },
            { id: "owner", visible: true },
            { id: "inputs", visible: true },
            { id: "outputs", visible: true }
          ]}
          items={simResultUrl.map(item => {
            return {

              scenarioId:
                <SpaceBetween direction='horizontal'>
                  <>
                    {item.name}
                  </>

                  <Popover
                    size="small"
                    position="top"
                    triggerType="custom"
                    dismissButton={false}
                    content={<StatusIndicator type="success">Scenario Id copied</StatusIndicator>}
                  >
                    <Button
                      iconName="copy"
                      variant="inline-icon"
                      onClick={() => {
                        navigator.clipboard.writeText(item.name);
                      }}
                    >

                    </Button>
                  </Popover>
                </SpaceBetween>
              ,
              status: <StatusIndicator type={item.status}>{item.status}</StatusIndicator>,
              date: item.startDate,
              inputs: JSON.stringify(item.inputs.queryStringParameters, undefined, 4),
              outputs: JSON.stringify(item.outputs, undefined, 4)
              // owner: "dhbesson@amazon.com",
              // inputs: "Inputs",
              // outputs: <a href={item.presignedUrl} target="_blank" rel="noreferrer">
              //   <Icon name='download' />
              // </a>
            }

          })}
          loadingText="Loading resources"
          // selectionType="multi"
          // trackBy="name"
          empty={
            <Box
              margin={{ vertical: "xs" }}
              textAlign="center"
              color="inherit"
            >
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button>Create resource</Button>
              </SpaceBetween>
            </Box>
          }
          filter={
            <TextFilter
              filteringPlaceholder="Find simulations"
              filteringText=""
            />
          }
          header={
            <Header
              variant="awsui-h1-sticky"
            // counter={
            //   selectedItems.length
            //     ? "(" + selectedItems.length + "/10)"
            //     : "(10)"
            // }
            >
              Simulations <Button
                iconName="refresh"
                variant="inline-icon"
                onClick={() => {
                  getSimStatus();
                }}
              >

              </Button>
            </Header>
          }
          pagination={
            <Pagination currentPageIndex={1} pagesCount={2} />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{
                pageSize: 10,
                contentDisplay: [
                  { id: "scenarioId", visible: true },
                  { id: "status", visible: true },
                  { id: "date", visible: true },
                  { id: "owner", visible: true },
                  { id: "inputs", visible: true },
                  { id: "outputs", visible: true }
                ]
              }}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 10, label: "10 resources" },
                  { value: 20, label: "20 resources" }
                ]
              }}
              wrapLinesPreference={{}}
              stripedRowsPreference={{}}
              contentDensityPreference={{}}
              contentDisplayPreference={{
                options: [
                  {
                    id: "variable",
                    label: "Variable name",
                    alwaysVisible: true
                  },
                  { id: "value", label: "Text value" },
                  { id: "type", label: "Type" },
                  { id: "description", label: "Description" }
                ]
              }}
              stickyColumnsPreference={{
                firstColumns: {
                  title: "Stick first column(s)",
                  description:
                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                  options: [
                    { label: "None", value: 0 },
                    { label: "First column", value: 1 },
                    { label: "First two columns", value: 2 }
                  ]
                },
                lastColumns: {
                  title: "Stick last column",
                  description:
                    "Keep the last column visible while horizontally scrolling the table content.",
                  options: [
                    { label: "None", value: 0 },
                    { label: "Last column", value: 1 }
                  ]
                }
              }}
            />
          }
        />
      </ContentLayout>

    </>
  }

  function LandingPage() {


    return <>
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              info={<Link>Info</Link>}
              description="Applications available within the AWS Cloud Mission Operations Center (CMOC)."
            // actions={
            //   <Button variant="primary">Button</Button>
            // }
            >
              Application Launcher
            </Header>

            {/* <Alert statusIconAriaLabel="Info">
            This is a generic alert.
          </Alert> */}
          </SpaceBetween>
        }
      >
        <ScrollView width="100%" height="90vh" >


          <Container

          >
            <SpaceBetween size='l'>
              <ExpandableSection headerText='Bus and Payload Operations'>
                <Cards
                  ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                  }}
                  cardDefinition={{
                    header: item => (
                      <Link href={item.link} fontSize="heading-m">
                        {item.name}
                      </Link>
                    ),
                    sections: [
                      {
                        id: "description",
                        // header: "Description",
                        content: item => item.description
                      },
                      {
                        id: "preview",
                        // header: "Type",
                        content: item => item.preview
                      }
                    ]
                  }}
                  cardsPerRow={[
                    { cards: 3 },
                    { minWidth: 500, cards: 3 }
                  ]}
                  items={[
                    {
                      name: "Yamcs",
                      link: "https://missionops.fcesarmo.people.aws.dev/",
                      alt: "Third",
                      description: "Mission operations software suite",
                      preview:
                        <a href="https://missionops.fcesarmo.people.aws.dev/" target="_blank" rel="noreferrer">
                          <img src={yamcsPreview} width='700px' alt="Logo" />
                        </a>
                      ,
                    },
                    {
                      name: "Cesium Space Situational Awareness (SSA)",
                      link: "https://missionops.fcesarmo.people.aws.dev/",
                      alt: "Third",
                      description: "Visualize space assets",
                      preview:
                        <a href="https://missionops.fcesarmo.people.aws.dev/" target="_blank" rel="noreferrer">
                          <img src={'/cesium.png'} width='400px' alt="Logo" />
                        </a>
                      ,
                    },
                    {
                      name: "OpenMCT",
                      link: "https://missionops.fcesarmo.people.aws.dev/",
                      alt: "Third",
                      description: "NASA-developed telemetry visualization tool",
                      preview:
                        <a href="https://missionops.fcesarmo.people.aws.dev/" target="_blank" rel="noreferrer">
                          <img src={'/openmct.jpg'} width='400px' alt="Logo" />
                        </a>
                      ,
                    },
                    {
                      name: "IoT SiteWise",
                      link: "https://missionops.fcesarmo.people.aws.dev/",
                      alt: "Third",
                      description: "IoT configuration and management tool",
                      preview:
                        <a href="https://missionops.fcesarmo.people.aws.dev/" target="_blank" rel="noreferrer">
                          <img src={'/sitewise.png'} width='700px' alt="Logo" />
                        </a>
                      ,
                    },
                    {
                      name: "Grafana",
                      alt: "Fourth",
                      link: "https://g-5ea8a94966.grafana-workspace.us-east-1.amazonaws.com/d/aH139fvIz/home?orgId=1&from=1702943932188&to=1702944325478&var-simulationId=lambda-test-29",
                      description: "Time-series data dashboards",

                      preview: <a href="https://g-5ea8a94966.grafana-workspace.us-east-1.amazonaws.com/d/aH139fvIz/home?orgId=1&from=1702943932188&to=1702944325478&var-simulationId=lambda-test-29" target="_blank" rel="noreferrer">
                        <img src={grafanaPreview} width='700px' alt="Logo" />
                      </a>
                      ,
                    },
                    {
                      name: "QuickSight",
                      alt: "Fifth",
                      link: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1",
                      description: "AI/ML-powered data analytics",
                      preview: <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1" target="_blank" rel="noreferrer">
                        <img src={quicksightPreview} width='700px' alt="Logo" />
                      </a>,
                      // size: "Large"
                    },
                    {
                      name: "ChatBot Assistant",
                      alt: "Fifth",
                      link: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1",
                      description: "Voice and text chatbot assistent for logistics management",
                      preview: <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1" target="_blank" rel="noreferrer">
                        <img src={'/chat.png'} width='700px' alt="Logo" />
                      </a>,
                      // size: "Large"
                    }
                  ]}
                  loadingText="Loading resources"
                  empty={
                    <Box
                      margin={{ vertical: "xs" }}
                      textAlign="center"
                      color="inherit"
                    >
                      <SpaceBetween size="m">
                        <b>No resources</b>
                        <Button>Create resource</Button>
                      </SpaceBetween>
                    </Box>
                  }
                // header={ }
                />
              </ExpandableSection>



              <ExpandableSection headerText='Digital Twin Simulator'>
                <Cards
                  ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                  }}
                  cardDefinition={{
                    header: item => (
                      <Link href={item.link} fontSize="heading-m">
                        {item.name}
                      </Link>
                    ),
                    sections: [
                      {
                        id: "description",
                        // header: "Description",
                        content: item => item.description
                      },
                      {
                        id: "preview",
                        // header: "Type",
                        content: item => item.preview
                      }
                    ]
                  }}
                  cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 3 }
                  ]}
                  items={[
                    {
                      name: "Create simulation",
                      link: "create",
                      alt: "First",
                      description: "Digital twin mission scenarios",
                      type: "1A",
                      size: "Small"
                    },
                    {
                      name: "View simulations",
                      link: "view",
                      alt: "Second",
                      description: "List of existing mission scenario simulations",
                      type: "1B",
                      size: "Large"
                    },
                  ]}
                  loadingText="Loading resources"
                  empty={
                    <Box
                      margin={{ vertical: "xs" }}
                      textAlign="center"
                      color="inherit"
                    >
                      <SpaceBetween size="m">
                        <b>No resources</b>
                        <Button>Create resource</Button>
                      </SpaceBetween>
                    </Box>
                  }
                // header={<Header>Example Cards</Header>}
                />
              </ExpandableSection>

              <ExpandableSection headerText='Antenna Contact Scheduling'>
                <Cards
                  ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                  }}
                  cardDefinition={{
                    header: item => (
                      <Link href={item.link} fontSize="heading-m">
                        {item.name}
                      </Link>
                    ),
                    sections: [
                      {
                        id: "description",
                        // header: "Description",
                        content: item => item.description
                      },
                      {
                        id: "preview",
                        // header: "Type",
                        content: item => item.preview
                      }
                    ]
                  }}
                  cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 3 }
                  ]}
                  items={[
                    {
                      name: "Antennas",
                      link: "create",
                      alt: "First",
                      description: "Manage antenna assets",
                      type: "1A",
                      size: "Small"
                    },
                    {
                      name: "Space Link Providers (SLPs)",
                      link: "view",
                      alt: "Second",
                      description: "Manage interfaces with SLPs",
                      type: "1B",
                      size: "Large"
                    },
                    {
                      name: "Missions",
                      link: "view",
                      alt: "Second",
                      description: "Manage active space missions",
                      type: "1B",
                      size: "Large"
                    },
                    {
                      name: "Vehicles",
                      link: "view",
                      alt: "Second",
                      description: "Manage active space vehicles",
                      type: "1B",
                      size: "Large"
                    },
                    {
                      name: "Schedule",
                      link: "view",
                      alt: "Second",
                      description: "Manage antenna contact schedule",
                      type: "1B",
                      size: "Large"
                    },

                  ]}
                  loadingText="Loading resources"
                  empty={
                    <Box
                      margin={{ vertical: "xs" }}
                      textAlign="center"
                      color="inherit"
                    >
                      <SpaceBetween size="m">
                        <b>No resources</b>
                        <Button>Create resource</Button>
                      </SpaceBetween>
                    </Box>
                  }
                // header={<Header>Example Cards</Header>}
                />
              </ExpandableSection>


            </SpaceBetween>
          </Container>
          {/* <Container
        header={
          <Header
            variant="h2"
            description="Generate telemetry for simulated mission scenarios."
          >
            Digital Twin Simulator
          </Header>
        }
      >
         
      </Container>
      <Container
        header={
          <Header
            variant="h2"
            description="Manage scheduling system assets and request antenna contacts."
          >
            Antenna Contact Scheduling
          </Header>
        }
      >
         
      </Container> */}

        </ScrollView>
      </ContentLayout>

    </>
  }
  function CreateSimulation() {

    return <form>

      <Wizard
        i18nStrings={{
          stepNumberLabel: stepNumber =>
            `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) =>
            `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Start simulation",
          optional: "optional"
        }}
        onNavigate={({ detail }) =>
          setActiveStepIndex(detail.requestedStepIndex)
        }
        onSubmit={({ detail }) =>
          showFlashbar(detail)
        }
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Orbit parameters",
            info: <Link variant="info">Info</Link>,
            description:
              "Orbit stuff.",
            content: (
              <Container
                header={
                  <Header variant="h2">
                    Orbit parameters
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Radius (km)">
                    <Input inputMode='decimal'
                      value={radius}
                      onChange={event =>
                        setRadius(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Inclination (deg)">
                    <Input
                      inputMode='decimal'
                      value={inclination}
                      onChange={event =>
                        setInclination(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="True anomaly (deg)">
                    <Input
                      inputMode='decimal'
                      value={trueAnomaly}
                      onChange={event =>
                        setTrueAnomaly(event.detail.value)
                      } />
                  </FormField>
                </SpaceBetween>
              </Container>
            )
          },
          {
            title: "Spacecraft configuration",
            content: (
              <Container
                header={
                  <Header variant="h2">
                    Form container header
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Solar panel area (m2)">
                    <Input inputMode='decimal'
                      value={solarPanelArea}
                      onChange={event =>
                        setSolarPanelArea(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Solar panel efficiency (%)">
                    <Input inputMode='decimal'
                      value={solarPanelEfficiency}
                      onChange={event =>
                        setSolarPanelEfficiency(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Attitude rate [x, y, z]">
                    <SpaceBetween direction='horizontal' size='l'>
                      <Input inputMode='decimal'
                        value={attRateX}
                        onChange={event =>
                          setAttRateX(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={attRateY}
                        onChange={event =>
                          setAttRateY(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={attRateZ}
                        onChange={event =>
                          setAttRateZ(event.detail.value)
                        } />
                    </SpaceBetween>

                  </FormField>
                  <FormField label="Attitude control parameters [K, P, Ki, Integral Limit]">
                    <SpaceBetween direction='horizontal' size='l'>
                      <Input inputMode='decimal'
                        value={controlK}
                        onChange={event =>
                          setControlK(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={controlP}
                        onChange={event =>
                          setControlP(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={controlKi}
                        onChange={event =>
                          setControlKi(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={controlIntLim}
                        onChange={event =>
                          setControlIntLim(event.detail.value)
                        } />
                    </SpaceBetween>
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
            // isOptional: true
          },
          {
            title: "Time parameters",
            content: (
              <Container
                header={
                  <Header variant="h2">
                    Form container header
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Simulation type">
                    <Select
                      selectedOption={simType}
                      onChange={({ detail }) =>
                        setSimType(detail.selectedOption)
                      }

                      options={[
                        { label: "Archive", value: "archive" },
                        { label: "Realtime", value: "realtime" }
                      ]}
                    />
                  </FormField>
                  <FormField label="Interval duration (ticks)">
                    <Input inputMode='decimal'
                      value={intervalDuration}
                      onChange={event =>
                        setIntervalDuration(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Simulation tick interval (s)">
                    <Input inputMode='decimal'
                      value={tickInterval}
                      onChange={event =>
                        setTickInterval(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Output measurement interval (s)">
                    <Input inputMode='decimal'
                      value={measurementInterval}
                      onChange={event =>
                        setMeasurementInterval(event.detail.value)
                      } />
                  </FormField>
                  <FormField label="Epoch start date [year, month, day]">
                    <SpaceBetween direction='horizontal' size='l'>
                      <Input inputMode='decimal'
                        value={startYear}
                        onChange={event =>
                          setStartYear(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={startMonth}
                        onChange={event =>
                          setStartMonth(event.detail.value)
                        } />
                      <Input inputMode='decimal'
                        value={startDay}
                        onChange={event =>
                          setStartDay(event.detail.value)
                        } />
                    </SpaceBetween>

                  </FormField>
                </SpaceBetween>
              </Container>
            ),
            // isOptional: true
          },
          {
            title: "Review and submit",
            content: (
              <SpaceBetween size="l">
                <SpaceBetween size="xs">
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(0)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 1: Orbit parameters
                  </Header>
                  <Container
                  // header={
                  //   <Header variant="h2">
                  //     Selections
                  //   </Header>
                  // }
                  >
                    <ColumnLayout
                      columns={3}
                      variant="text-grid"
                    >
                      <div>
                        <Box variant="awsui-key-label">
                          Radius (km)
                        </Box>
                        <div>{radius}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Inclination (deg)
                        </Box>
                        <div>{inclination}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          True anomaly (deg)
                        </Box>
                        <div>{trueAnomaly}</div>
                      </div>
                    </ColumnLayout>
                  </Container>
                </SpaceBetween>
                <SpaceBetween size="xs">
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(1)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 2: Spacecraft
                  </Header>
                  <Container
                  // header={
                  //   <Header variant="h2">
                  //     Selections
                  //   </Header>
                  // }
                  >
                    <ColumnLayout
                      columns={3}
                      variant="text-grid"
                    >
                      <div>
                        <Box variant="awsui-key-label">
                          Solar panel area (m2)
                        </Box>
                        <div>{solarPanelArea}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Solar panel efficiency (%)
                        </Box>
                        <div>{solarPanelEfficiency}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Attitude rate (deg/s)
                        </Box>
                        <div>[{attRateX}, {attRateY}, {attRateZ}]</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Attitude control parameters [K, P, Ki, Integral Limit]
                        </Box>
                        <div>[{controlK}, {controlP}, {controlKi}, {controlIntLim}]</div>
                      </div>
                    </ColumnLayout>
                  </Container>
                </SpaceBetween>
                <SpaceBetween size="xs">
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(2)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 3: Time parameters
                  </Header>
                  <Container
                  // header={
                  //   <Header variant="h2">
                  //     Selections
                  //   </Header>
                  // }
                  >
                    <ColumnLayout
                      columns={3}
                      variant="text-grid"
                    >
                      <div>
                        <Box variant="awsui-key-label">
                          Simulation type
                        </Box>
                        <div>{simType.label}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Simulation interval (ticks)
                        </Box>
                        <div>{intervalDuration}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Tick interval (s)
                        </Box>
                        <div>{tickInterval}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Measurement interval (s)
                        </Box>
                        <div>{measurementInterval}</div>
                      </div>
                      <div>
                        <Box variant="awsui-key-label">
                          Start date epoch [year, month, day]
                        </Box>
                        <div>{startYear}-{startMonth}-{startDay}</div>
                      </div>
                    </ColumnLayout>
                  </Container>
                </SpaceBetween>
              </SpaceBetween>
            )
          }
        ]}
      />
    </form>
  }





  function Missions() {

    return <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="Missions currently tracked by the scheduling system."
          // actions={
          //   <Button variant="primary">Button</Button>
          // }
          >
            Missions
          </Header>

          {/* <Alert>This is a generic alert.</Alert> */}
        </SpaceBetween>
      }
    >
      <Modal
        onDismiss={() => setMissionModalVisible(false)}
        visible={missionModalVisible}
        // footer={
        //   <Box float="right">
        //     <SpaceBetween direction="horizontal" size="xs">
        //       <Button variant="link">Cancel</Button>
        //       <Button variant="primary">Ok</Button>
        //     </SpaceBetween>
        //   </Box>
        // }
        header="Create Mission"
      >

        {MissionInput()}
      </Modal>
      <Table
        // onSelectionChange={({ detail }) =>
        //   setSelectedItems(detail.selectedItems)
        // }
        // selectedItems={selectedItems}
        // ariaLabels={{
        //   selectionGroupLabel: "Items selection",
        //   allItemsSelectionLabel: ({ selectedItems }) =>
        //     `${selectedItems.length} ${
        //       selectedItems.length === 1 ? "item" : "items"
        //     } selected`,
        //   itemSelectionLabel: ({ selectedItems }, item) =>
        //     item.name
        // }}

        resizableColumns
        wrapLines
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            // cell: item => <Link href="#">{item.scenarioId}</Link>,
            cell: item => item.name,
            sortingField: "name",
            isRowHeader: true
          },
          {
            id: "status",
            header: "Status",
            cell: item => item.status,
            sortingField: "status"
          },
          {
            id: "vehicles",
            header: "Vehicles",
            cell: item => item.vehicles,
            sortingField: "vehicles"
          },
          {
            id: "budget",
            header: "Contact budget (min/day)",
            cell: item => item.budget,
            sortingField: "budget"
          },
          {
            id: "slppreference",
            header: "SLP preferences",
            cell: item => item.slppreference,
            sortingField: "slppreference"
          }
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "status", visible: true },
          { id: "vehicles", visible: true },
          { id: "budget", visible: true },
          { id: "slppreference", visible: true },
        ]}
        items={missionList


        }
        loadingText="Loading resources"
        // selectionType="multi"
        // trackBy="name"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find Missions"
            filteringText=""
          />
        }
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                {/* <ButtonDropdown
                      items={[
                        {
                          text: "Deactivate",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Activate",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Status 3",
                          id: "rn",
                          disabled: false
                        },
                        {
                          text: "View details",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Edit",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Delete",
                          id: "rn",
                          disabled: false
                        }
                      ]}
                    >
                      Actions
                    </ButtonDropdown> */}
                <Button
                  iconName="refresh"
                  // variant="inline-icon"
                  onClick={() => {
                    getSimStatus();
                  }}
                >

                </Button>
                <Button>Edit Mission</Button>
                <Button variant="primary" onClick={() => {
                  setMissionModalVisible(true);
                }}>
                  Create Mission
                </Button>
              </SpaceBetween>
            }
          // counter={
          //   selectedItems.length
          //     ? "(" + selectedItems.length + "/10)"
          //     : "(10)"
          // }
          >

          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={2} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "name", visible: true },
                { id: "status", visible: true },
                { id: "slp", visible: true },
                { id: "location", visible: true },
                { id: "bands", visible: true },
                { id: "aperature", visible: true },
                { id: "slew", visible: true }
              ]
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            wrapLinesPreference={{}}
            stripedRowsPreference={{}}
            contentDensityPreference={{}}
            contentDisplayPreference={{
              options: [
                {
                  id: "variable",
                  label: "Variable name",
                  alwaysVisible: true
                },
                { id: "value", label: "Text value" },
                { id: "type", label: "Type" },
                { id: "description", label: "Description" }
              ]
            }}
            stickyColumnsPreference={{
              firstColumns: {
                title: "Stick first column(s)",
                description:
                  "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "First column", value: 1 },
                  { label: "First two columns", value: 2 }
                ]
              },
              lastColumns: {
                title: "Stick last column",
                description:
                  "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "Last column", value: 1 }
                ]
              }
            }}
          />
        }
      />


    </ContentLayout>


  }

  function Vehicles() {

    return <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="Vehicles currently tracked by the scheduling system."
          // actions={
          //   <Button variant="primary">Button</Button>
          // }
          >
            Vehicles
          </Header>

          {/* <Alert>This is a generic alert.</Alert> */}
        </SpaceBetween>
      }
    >
      <Modal
        onDismiss={() => setVehicleModalVisible(false)}
        visible={vehicleModalVisible}
        // footer={
        //   <Box float="right">
        //     <SpaceBetween direction="horizontal" size="xs">
        //       <Button variant="link">Cancel</Button>
        //       <Button variant="primary">Ok</Button>
        //     </SpaceBetween>
        //   </Box>
        // }
        header="Create Vehicle"
      >

        {VehicleInput()}
      </Modal>
      <Table
        // onSelectionChange={({ detail }) =>
        //   setSelectedItems(detail.selectedItems)
        // }
        // selectedItems={selectedItems}
        // ariaLabels={{
        //   selectionGroupLabel: "Items selection",
        //   allItemsSelectionLabel: ({ selectedItems }) =>
        //     `${selectedItems.length} ${
        //       selectedItems.length === 1 ? "item" : "items"
        //     } selected`,
        //   itemSelectionLabel: ({ selectedItems }, item) =>
        //     item.name
        // }}

        resizableColumns
        wrapLines
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            // cell: item => <Link href="#">{item.scenarioId}</Link>,
            cell: item => item.name,
            sortingField: "name",
            isRowHeader: true
          },
          {
            id: "status",
            header: "Status",
            cell: item => item.status,
            sortingField: "status"
          },
          {
            id: "mission",
            header: "Mission",
            cell: item => item.mission,
            sortingField: "mission"
          },
          {
            id: "bands",
            header: "Band(s)",
            cell: item => item.bands,
            sortingField: "bands"
          },
          {
            id: "aperature",
            header: "Minimum aperature (m)",
            cell: item => item.aperature,
            sortingField: "aperature"
          }
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "status", visible: true },
          { id: "mission", visible: true },
          { id: "bands", visible: true },
          { id: "aperature", visible: true },
        ]}
        items={vehicleList


        }
        loadingText="Loading resources"
        // selectionType="multi"
        // trackBy="name"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find Vehicles"
            filteringText=""
          />
        }
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                {/* <ButtonDropdown
                      items={[
                        {
                          text: "Deactivate",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Activate",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Status 3",
                          id: "rn",
                          disabled: false
                        },
                        {
                          text: "View details",
                          id: "rm",
                          disabled: false
                        },
                        {
                          text: "Edit",
                          id: "mv",
                          disabled: false
                        },
                        {
                          text: "Delete",
                          id: "rn",
                          disabled: false
                        }
                      ]}
                    >
                      Actions
                    </ButtonDropdown> */}
                <Button
                  iconName="refresh"
                  // variant="inline-icon"
                  onClick={() => {
                    getSimStatus();
                  }}
                >

                </Button>
                <Button>Edit Mission</Button>
                <Button variant="primary" onClick={() => {
                  setVehicleModalVisible(true);
                }}>
                  Create Vehicle
                </Button>
              </SpaceBetween>
            }
          // counter={
          //   selectedItems.length
          //     ? "(" + selectedItems.length + "/10)"
          //     : "(10)"
          // }
          >

          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={2} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "name", visible: true },
                { id: "status", visible: true },
                { id: "slp", visible: true },
                { id: "location", visible: true },
                { id: "bands", visible: true },
                { id: "aperature", visible: true },
                { id: "slew", visible: true }
              ]
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            wrapLinesPreference={{}}
            stripedRowsPreference={{}}
            contentDensityPreference={{}}
            contentDisplayPreference={{
              options: [
                {
                  id: "variable",
                  label: "Variable name",
                  alwaysVisible: true
                },
                { id: "value", label: "Text value" },
                { id: "type", label: "Type" },
                { id: "description", label: "Description" }
              ]
            }}
            stickyColumnsPreference={{
              firstColumns: {
                title: "Stick first column(s)",
                description:
                  "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "First column", value: 1 },
                  { label: "First two columns", value: 2 }
                ]
              },
              lastColumns: {
                title: "Stick last column",
                description:
                  "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                  { label: "None", value: 0 },
                  { label: "Last column", value: 1 }
                ]
              }
            }}
          />
        }
      />


    </ContentLayout>


  }

  function Schedule() {

    return <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="Current master schedule."
          // actions={
          //   <Button variant="primary">Button</Button>
          // }
          >
            Schedule
          </Header>

          {/* <Alert>This is a generic alert.</Alert> */}
        </SpaceBetween>
      }
    >
      <Modal
        onDismiss={() => setScheduleModalVisible(false)}
        visible={scheduleModalVisible}
        // footer={
        //   <Box float="right">
        //     <SpaceBetween direction="horizontal" size="xs">
        //       <Button variant="link">Cancel</Button>
        //       <Button variant="primary">Ok</Button>
        //     </SpaceBetween>
        //   </Box>
        // }
        header="Create scheduled contact"
      >

        <form onSubmit={e => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link">
                  Cancel
                </Button>
                <Button variant="primary">Create</Button>
              </SpaceBetween>
            }
          // header={<Header variant="h1">Form header</Header>}
          >
            {/* <Container
          header={
            <Header variant="h2">
              Form container header
            </Header>
          }
        > */}
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Vehicle">
                <Select
                  // selectedOption={selectedOption}
                  // onChange={({ detail }) =>
                  //   setSelectedOption(detail.selectedOption)
                  // }
                  placeholder='Choose mission'
                  options={[
                    { label: "ISS", value: "iss" },
                    { label: "JPSS-1", value: "jpss" },
                    // { label: "KSAT", value: "ksat" },
                  ]}
                />
              </FormField>
              <FormField label="Antenna">
                <Select
                  // selectedOption={selectedOption}
                  // onChange={({ detail }) =>
                  //   setSelectedOption(detail.selectedOption)
                  // }
                  placeholder='Choose antenna'
                  options={[
                    { label: "AWS-01", value: "aws01" },
                    { label: "AWS-02", value: "aws02" },
                    { label: "KSAT-01", value: "ksat01" },
                    { label: "KSAT-02", value: "ksat02" },
                    { label: "NASA-01", value: "nasa01" },
                    { label: "NASA-02", value: "nasa02" },

                    // { label: "KSAT", value: "ksat" },
                  ]}
                />
              </FormField>
              <FormField label="Interval">
                <DateRangePicker
                  onChange={({ detail }) => setDaterange(detail.value)}
                  value={daterange}


                  i18nStrings={{}}
                  placeholder="Filter by a date and time range"
                />
              </FormField>
              <FormField label="Status">
                <Select
                  // selectedOption={selectedOption}
                  // onChange={({ detail }) =>
                  //   setSelectedOption(detail.selectedOption)
                  // }
                  placeholder='Choose Status'
                  options={[
                    { label: "Confirmed", value: "confirmed" },
                    { label: "Available", value: "available" },
                    { label: "Conflict", value: "conflict" },
                  ]}
                />
              </FormField>
            </SpaceBetween>
            {/* </Container> */}
          </Form>
        </form>
      </Modal>
      {AntennaScheduleTable()}


    </ContentLayout>


  }


  async function showFlashbar(event) {
    const myInit = {
      // headers: {}, // OPTIONAL
      // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        radius: radius,
        inclination: inclination,
        true_anomaly: trueAnomaly,
        solarPanelArea: solarPanelArea,
        solarPanelEfficiency: solarPanelEfficiency,
        simTick: tickInterval,
        simInterval: intervalDuration,
        outputInterval: measurementInterval,
        year: startYear,
        month: startMonth,
        day: startDay,
        sim_type: simType.value,
        attRateX: attRateX,
        attRateY: attRateY,
        attRateZ: attRateZ,
        controlK: controlK,
        controlP: controlP,
        controlKi: controlKi,
        controlIntLim: controlIntLim,
      }
    };

    await API.get(apiName, path, myInit)
      .then((response) => {
        setSimId(response['scenarioId'])
        setSimItems(
          [{
            type: "success",
            content: "Simulation started successfully. Simulation ID is " + response['scenarioId'],
            // action: <Button>View simulation status</Button>,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setSimItems([]),
            id: "message_1"
          }]
        )
        setActiveStepIndex(0)
        setRadius('6671');
        setInclination('30');
        setTrueAnomaly('0');
        setSolarPanelArea('0.1');
        setSolarPanelEfficiency('0.2');
        setIntervalDuration('4000');
        setTickInterval('0.05');
        setMeasurementInterval('5');
        setStartYear(2023);
        setStartMonth(12);
        setStartDay(19);
        setSimType({ label: "Archive", value: "archive" });
        setAttRateX(0.2);
        setAttRateY(0.1);
        setAttRateZ(0.05);
        setControlK(3.5);
        setControlP(30);
        setControlKi(-1);
        setControlIntLim(-20);
      }
      )
      .catch((error) => {
        console.log(error.response);
      });
  }


  const apiName = 'CmocDigitalTwinApi';
  const path = '/simulation';

  const location = useLocation()
  console.log(location.pathname)


  return (
    <ThemeProvider>
      <>
        <TopNavigation
          i18nStrings={i18nStrings}
          identity={{
            href: '#',
            title: <SpaceBetween direction='horizontal' size='m'>
              <img src="/amasat.png" width='40px' />
              Cloud Mission Operations Center
            </SpaceBetween>,

            // logo: { src: logo, alt: 'Service name logo', width: '10px' },
          }}

          utilities={[
            // {
            //   type: 'button',
            //   iconName: 'notification',
            //   ariaLabel: 'Notifications',
            //   badge: true,
            //   disableUtilityCollapse: true,
            // },
            // { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },
            {
              type: 'menu-dropdown',
              text: user.attributes.email,
              description: user.attributes.email,
              iconName: 'user-profile',
              items: profileActions,
              onItemClick: escapeFunc
            },
          ]}
        />
        <AppLayout
          stickyNotifications
          // toolsHide
          notifications={<Flashbar items={simItems} />}
          contentType='dashboard'
          // navigationHide
          ariaLabels={{ navigationClose: 'close' }}
          navigation={
            <SideNavigation
              activeHref={activeHref}
              // header={{ href: "#/", text: "Service name" }}
              onFollow={event => {
                if (!event.detail.external) {
                  event.preventDefault();
                  console.log(event.detail.href)
                  navigate(event.detail.href);
                  setActiveHref(event.detail.href);
                  setContentType(event.detail.contentType)

                }
              }}
              header={{
                text: "Application Launcher", href: '/',
                // logo: { alt: "logo", src: "/launch2.png" }
              }}
              items={[

                {
                  type: "section",
                  text: "Bus and Payload Operations",
                  items: [
                    {
                      type: "link",
                      text: "Yamcs",
                      href: "https://missionops.fcesarmo.people.aws.dev/",
                      external: true
                    },
                    {
                      type: "link",
                      text: "Cesium SSA",
                      href: "https://missionops.fcesarmo.people.aws.dev/",
                      external: true
                    },
                    {
                      type: "link",
                      text: "OpenMCT",
                      href: "https://missionops.fcesarmo.people.aws.dev/",
                      external: true
                    },
                    {
                      type: "link",
                      text: "IoT SiteWise",
                      href: "https://missionops.fcesarmo.people.aws.dev/",
                      external: true
                    },
                    {
                      type: "link",
                      text: "Grafana",
                      href: "https://g-5ea8a94966.grafana-workspace.us-east-1.amazonaws.com/d/aH139fvIz/home?orgId=1&from=1702943932188&to=1702944325478&var-simulationId=lambda-test-29",
                      external: true
                    },
                    {
                      type: "link",
                      text: "QuickSight",
                      href: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1",
                      external: true
                    },
                    {
                      type: "link",
                      text: "ChatBot Assistant",
                      href: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5e264d8f-dd7e-4884-a80b-b3e0616822a1",
                      external: true
                    }
                  ]
                },
                {
                  type: "section",
                  text: "Digital Twin Simulator",
                  items: [
                    { type: "link", text: "Create simulation", href: '/create', contentType: 'wizard' },
                    { type: "link", text: "View simulation status", href: '/view', contentType: 'table' },
                  ]
                },
                {
                  type: "section",
                  text: "Antenna Contact Scheduling",
                  items: [
                    {
                      type: "link",
                      text: "Antennas",
                      href: "/antenna"
                    },
                    {
                      type: "link",
                      text: "SLPs",
                      href: "/slp"
                    },
                    {
                      type: "link",
                      text: "Missions",
                      href: "/mission"
                    },
                    {
                      type: "link",
                      text: "Vehicles",
                      href: "/vehicle"
                    },
                    // {
                    //   type: "link",
                    //   text: "Requests",
                    //   href: "/request"
                    // },
                    {
                      type: "link",
                      text: "Schedule",
                      href: "/schedule"
                    },

                  ]
                },

              ]}
            />
          }
          maxContentWidth={Number.MAX_VALUE}
          // breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
          // contentType="table"

          content={
            // <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route exact path="/create" element={<CreateSimulation />} />
              <Route exact path="/view" element={<ViewSimulations />} />
              <Route exact path="/antenna" element={<Antennas />} />
              <Route exact path="/slp" element={<Slps />} />
              <Route exact path="/mission" element={<Missions />} />
              <Route exact path="/vehicle" element={<Vehicles />} />
              <Route exact path="/schedule" element={<Schedule />} />
              {/* <Route index element={<CreateSimulation />} /> */}
              {/* <Route exact path="create" component={CreateSimulation} /> */}

              {/* <Route path="contact" element={<Contact />} />
                  <Route path="*" element={<NoPage />} /> */}
              {/* </Route> */}
            </Routes>
            // </BrowserRouter>

          }

          tools={
            <HelpPanel
              footer={
                <div>
                  <h3>
                    Learn more <CsIcon name="external" />
                  </h3>
                  <ul>
                    <li>
                      <a href="https://aws.amazon.com" target="_blank">Link to documentation</a>
                    </li>
                  </ul>
                </div>
              }
              header={<h2>Documentation</h2>}
            >
              <div>
                <p>
                  Documentation
                </p>
              </div>
            </HelpPanel>
          }
        // notifications={<Notifications />}
        />
      </>
    </ThemeProvider >
  );
}

export default withAuthenticator(App);
