/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://twbytotq2raj5evhobvu3ygkbu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-ndycghxlb5c3nbaeybiobhpywy",
    "aws_cloud_logic_custom": [
        {
            "name": "cmocAPI",
            "endpoint": "https://myfb1bi6j3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:009a45a3-be5a-464e-95c4-bba662ff8389",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nPEeafup8",
    "aws_user_pools_web_client_id": "nl7h8mtjsmqhr6plrj7ms1nsv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "cmoc-20231108142228-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1fec4zqlsrhh0.cloudfront.net",
    "aws_user_files_s3_bucket": "cmoc-storage-dev141738-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
